



























import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingAccountResponseDto } from "@/models/interface/accounting-account";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAccountParent extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingAccountResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams();
    params.sorts = "code:asc";
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAllParentAccount, toOptions } = useCoa();
    this.loading = true;
    findAllParentAccount(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useCoa();
    const params = new RequestQueryParams();
    params.search = filterBy({
      code: val,
      description: val,
    });
    params.sorts = "code:asc";

    this.fetchOptions(params);
  }

  findOption(value: string): Option<AccountingAccountResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
