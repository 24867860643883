const PurchaseOrderStatus = [
  "Approved",
  "Billed",
  "Cancelled",
  "Closed",
  "Draft",
  "Need Approval",
  "Partial Billed",
  "Partially Received",
  "Received",
  "Rejected",
] as const;
export type PurchaseOrderStatusEnum = (typeof PurchaseOrderStatus)[number];

/** @deprecated */
export enum PURCHASE_ORDER_STATUS {
  DRAFT = "Draft",
  SUBMITTED = "Need Approval",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  CANCELLED = "Cancelled",
  PARTIALLY_RECEIVED = "Partially Received",
  RECEIVED = "Received",
  PARTIAL_BILLED = "Partial Billed",
  BILLED = "Billed",
  CLOSED = "Closed",
}

export enum PURCHASE_ORDER_TYPE {
  RENT = "Rent to Rent",
  SERVICE = "Service",
  UNIT = "Unit",
  CAR = "Car",
  SPAREPART = "Sparepart",
  OTHERS = "Others",
}
