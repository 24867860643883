

















import { Option } from "@/models/class/option.class";
import { AccountingNormalBalance } from "@/models/enums/AccountingNormalBalance.enum";
import { Component, Vue, Prop } from "vue-property-decorator";

const NORMAL_BALANCE_OPTIONS: Option[] = [
  {
    key: 0,
    label: "lbl_debit",
    value: AccountingNormalBalance.DEBIT,
  },
  {
    key: 1,
    label: "lbl_credit",
    value: AccountingNormalBalance.CREDIT,
  },
];

@Component
export default class SelectNormalBalance extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option[] = NORMAL_BALANCE_OPTIONS;

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
