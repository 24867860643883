var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { sm: 24, md: 12 } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t(_vm.formRules.invoiceType.label) } },
            [
              _c("span", { staticClass: "ant-form-text" }, [
                _vm._v(_vm._s(_vm.$t("lbl_invoice_ap")))
              ])
            ]
          ),
          _vm.storeAPForm.invoiceNumber
            ? _c(
                "a-form-model-item",
                { attrs: { label: _vm.$t(_vm.formRules.invoiceNumber.label) } },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(" " + _vm._s(_vm.storeAPForm.invoiceNumber) + " ")
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.source.label),
                prop: "source"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  name: _vm.formRules.source.name,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_source")
                  }),
                  loading: _vm.loading.source,
                  disabled: _vm.allowEdit,
                  options: _vm.dataSource
                },
                on: { change: _vm.handleChangeSource },
                model: {
                  value: _vm.storeAPForm.source,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "source", $$v)
                  },
                  expression: "storeAPForm.source"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.branch.label),
                prop: "branch"
              }
            },
            [
              _c("SelectBranch", {
                attrs: { disabled: _vm.allowEdit, "label-in-value": "" },
                on: { change: _vm.handleChangeBranch },
                model: {
                  value: _vm.storeAPForm.branch,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "branch", $$v)
                  },
                  expression: "storeAPForm.branch"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.currency.label),
                prop: "currency"
              }
            },
            [
              _c("SelectCurrency", {
                attrs: {
                  "label-in-value": "",
                  disabled: _vm.allowEdit || _vm.disabled.currency
                },
                on: { change: _vm.handleChangeCurrency },
                model: {
                  value: _vm.storeAPForm.currency,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "currency", $$v)
                  },
                  expression: "storeAPForm.currency"
                }
              })
            ],
            1
          ),
          _vm.storeAPForm.currency && !_vm.isIDR
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.rate.label),
                    prop: "currencyRates"
                  }
                },
                [
                  _c("a-input-number", {
                    staticClass: "w-100",
                    attrs: {
                      name: _vm.formRules.rate.name,
                      disabled: _vm.allowEdit,
                      placeholder: _vm.$t("common.insert-text", {
                        text: _vm.$t("lbl_currency_rates")
                      }),
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      min: 0,
                      precision: _vm.storeBaseDecimalPlace
                    },
                    model: {
                      value: _vm.storeAPForm.currencyRates,
                      callback: function($$v) {
                        _vm.$set(_vm.storeAPForm, "currencyRates", $$v)
                      },
                      expression: "storeAPForm.currencyRates"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.supplierName.label),
                prop: "supplier"
              }
            },
            [
              _c("SelectSupplier", {
                attrs: { disabled: _vm.allowEdit },
                on: { change: _vm.handleChangeSupplier },
                model: {
                  value: _vm.storeAPForm.supplier,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "supplier", $$v)
                  },
                  expression: "storeAPForm.supplier"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.supplierShipAddress.label),
                prop: "supplierShipAddress"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  name: _vm.formRules.supplierShipAddress.name,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_supplier_ship_address")
                  }),
                  "show-search": "",
                  "allow-clear": "",
                  "dropdown-match-select-width": false,
                  options: _vm.dataSupplierShipAddress,
                  disabled: _vm.allowEdit
                },
                model: {
                  value: _vm.storeAPForm.supplierShipAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "supplierShipAddress", $$v)
                  },
                  expression: "storeAPForm.supplierShipAddress"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.supplierBillAddress.label),
                prop: "supplierBillAddress"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  name: _vm.formRules.supplierBillAddress.name,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_supplier_bill_address")
                  }),
                  "show-search": "",
                  "allow-clear": "",
                  disabled: _vm.allowEdit,
                  options: _vm.dataSupplierBillAddress,
                  "dropdown-match-select-width": false
                },
                model: {
                  value: _vm.storeAPForm.supplierBillAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "supplierBillAddress", $$v)
                  },
                  expression: "storeAPForm.supplierBillAddress"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.termOfPayment.label),
                prop: "termOfPayment"
              }
            },
            [
              _c("SelectTermOfPayment", {
                attrs: { disabled: _vm.allowEdit },
                model: {
                  value: _vm.storeAPForm.termOfPayment,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "termOfPayment", $$v)
                  },
                  expression: "storeAPForm.termOfPayment"
                }
              })
            ],
            1
          ),
          _vm.shouldGenerateFromGR || _vm.shouldGenerateFromPO
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.documentReferenceLabel,
                    prop: "documentReferences"
                  }
                },
                [
                  _vm.allowEdit
                    ? _c("span", [
                        _vm._v(" " + _vm._s(_vm.documentReferenceNumbers) + " ")
                      ])
                    : [
                        _c("a-select", {
                          attrs: {
                            name: "documentReference",
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.documentReferenceLabel
                            }),
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loading.docReference,
                            "label-in-value": "",
                            mode: "multiple",
                            options: _vm.dataDocReference
                          },
                          on: {
                            search: _vm.onSearchDocReference,
                            deselect: _vm.onDeselectDocReference
                          },
                          model: {
                            value: _vm.storeAPForm.documentReferences,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.storeAPForm,
                                "documentReferences",
                                $$v
                              )
                            },
                            expression: "storeAPForm.documentReferences"
                          }
                        }),
                        _c(
                          "a-button",
                          {
                            attrs: {
                              disabled: _vm.allowEdit,
                              type: "primary",
                              block: "",
                              loading: _vm.loading.generate
                            },
                            on: { click: _vm.handleGenerate }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("lbl_generate")) + " ")]
                        )
                      ]
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { sm: 24, md: 12 } },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.taxType.label),
                "label-align": "right",
                prop: "taxType"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_tax_type")
                  }),
                  name: _vm.formRules.taxType.name,
                  loading: _vm.loading.taxType,
                  disabled: true,
                  options: _vm.dataTaxType
                },
                on: { change: _vm.onChangeTaxType },
                model: {
                  value: _vm.storeAPForm.taxType,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "taxType", $$v)
                  },
                  expression: "storeAPForm.taxType"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.invoiceSupplierNo.label),
                prop: "invoiceSupplierNo",
                "label-align": "right"
              }
            },
            [
              _c("a-input", {
                attrs: {
                  name: _vm.formRules.invoiceSupplierNo.name,
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_invoice_supplier_number")
                  }),
                  disabled: _vm.allowEdit
                },
                model: {
                  value: _vm.storeAPForm.invoiceSupplierNo,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "invoiceSupplierNo", $$v)
                  },
                  expression: "storeAPForm.invoiceSupplierNo"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.invoiceDate.label),
                prop: "invoiceDate",
                "label-align": "right"
              }
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  name: _vm.formRules.invoiceDate.name,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_invoice_date")
                  }),
                  format: _vm.DATE_TIME_HOURS_DEFAULT_FORMAT,
                  disabled: _vm.allowEdit,
                  "disabled-date": _vm.disabledDate,
                  "show-time": ""
                },
                on: { change: _vm.onChangeInvoiceDate },
                model: {
                  value: _vm.storeAPForm.invoiceDate,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "invoiceDate", $$v)
                  },
                  expression: "storeAPForm.invoiceDate"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.accountingDate.label),
                prop: "accountingDate",
                "label-align": "right"
              }
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  value: _vm.storeAPForm.accountingDate,
                  name: _vm.formRules.accountingDate.name,
                  format: _vm.DEFAULT_DATE_FORMAT,
                  disabled: ""
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.invoiceReceivedDate.label),
                prop: "invoiceReceivedDate",
                "label-align": "right"
              }
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  name: _vm.formRules.invoiceReceivedDate.name,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_invoice_received_date")
                  }),
                  format: _vm.DEFAULT_DATE_FORMAT,
                  "disabled-date": _vm.disabledDateInvoiceReceived,
                  disabled: _vm.allowEdit
                },
                model: {
                  value: _vm.storeAPForm.invoiceReceivedDate,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "invoiceReceivedDate", $$v)
                  },
                  expression: "storeAPForm.invoiceReceivedDate"
                }
              })
            ],
            1
          ),
          _vm.$can("read", "general-journal") && !!_vm.storeAPForm.journalId
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.journalNumber.label),
                    "label-align": "right"
                  }
                },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "generaljournal.detail",
                          params: { id: _vm.storeAPForm.journalId }
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.storeAPForm.journalNumber) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.payablesAccount.label),
                prop: "payablesAccount",
                "label-align": "right"
              }
            },
            [
              _c("a-select", {
                attrs: {
                  "label-in-value": "",
                  options: _vm.dataPayablesAccount,
                  name: _vm.formRules.payablesAccount.name,
                  placeholder: _vm.$t("common.select-text", {
                    text: _vm.$t("lbl_payable_account")
                  }),
                  showSearch: true,
                  "filter-option": false,
                  loading: _vm.loading.payableAccount,
                  allowClear: true,
                  disabled: _vm.allowEdit
                },
                on: { search: _vm.onSearchPayableAccount },
                model: {
                  value: _vm.storeAPForm.payablesAccount,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "payablesAccount", $$v)
                  },
                  expression: "storeAPForm.payablesAccount"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.invoiceDescription.label),
                prop: "invoiceDescription",
                "label-align": "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "extra",
                  fn: function() {
                    return [
                      _c("character-length", {
                        attrs: { value: _vm.storeAPForm.invoiceDescription }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("a-textarea", {
                attrs: {
                  name: _vm.formRules.invoiceDescription.name,
                  placeholder: _vm.$t("common.insert-text", {
                    text: _vm.$t("lbl_invoice_description")
                  }),
                  disabled: _vm.allowEdit
                },
                model: {
                  value: _vm.storeAPForm.invoiceDescription,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "invoiceDescription", $$v)
                  },
                  expression: "storeAPForm.invoiceDescription"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t(_vm.formRules.isLuxuryGoods.label),
                prop: "isLuxuryGoods",
                "label-align": "right"
              }
            },
            [
              _c("a-switch", {
                attrs: { name: _vm.formRules.isLuxuryGoods.name, disabled: "" },
                model: {
                  value: _vm.storeAPForm.isLuxuryGoods,
                  callback: function($$v) {
                    _vm.$set(_vm.storeAPForm, "isLuxuryGoods", $$v)
                  },
                  expression: "storeAPForm.isLuxuryGoods"
                }
              })
            ],
            1
          ),
          _vm.storeAPForm.status
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRules.statusInvoice.label),
                    "label-align": "right"
                  }
                },
                [
                  _c("span", { staticClass: "ant-form-text" }, [
                    _vm._v(" " + _vm._s(_vm.storeAPForm.status) + " ")
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }