

















































import {
  DATE_TIME_HOURS_DEFAULT_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { ApInvoiceFormState } from "@/store/account-payable/ap-invoice/types";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      formState: (store: any) => store.invoiceApStore.formState,
    }),
    ...mapGetters({
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
  methods: {
    ...mapMutations({
      setFormState: "invoiceApStore/setFormState",
    }),
  },
})
export default class TabTaxDetailsInvoiceAp extends Vue {
  formState!: ApInvoiceFormState;
  setFormState!: (payload: Partial<ApInvoiceFormState>) => void;

  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  DATE_TIME_HOURS_DEFAULT_FORMAT = DATE_TIME_HOURS_DEFAULT_FORMAT;
  formRules = {
    taxRegistrationNumber: {
      label: "lbl_tax_registration_number",
      name: "taxRegistrationNumber",
      placeholder: "lbl_tax_registration_number_placeholder",
    },
    taxRegistrationName: {
      label: "lbl_tax_registration_name",
      name: "taxRegistrationName",
      placeholder: "lbl_tax_registration_name_placeholder",
    },
    taxInvoiceDate: {
      label: "lbl_tax_invoice_date",
      name: "taxInvoiceDate",
      placeholder: "lbl_tax_invoice_date_placeholder",
    },
    taxInvoiceNumber: {
      label: "lbl_tax_invoice_number",
      name: "taxInvoiceNumber",
      placeholder: "lbl_tax_invoice_number_placeholder",
    },
  };
}
