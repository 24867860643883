

















































































import SelectCurrency from "@/components/custom/select/SelectCurrency.vue";
import { useCoa } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import {
  AccountingAccountCreateRequestDto,
  AccountingAccountResponseDto,
  AccountingAccountTypeResponseDto,
  AccountingAccountUpdateRequestDto,
} from "@/models/interface/accounting-account";
import { AccountingCurrencyResponseDTO } from "@/models/interface/settings.interface";
import { FormValue, State } from "@/store/accountingAccount.store";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";
import { mapMutations, mapState, mapActions } from "vuex";
import SelectAccountParent from "./SelectAccountParent.vue";
import SelectAccountType from "./SelectAccountType.vue";
import SelectNormalBalance from "./SelectNormalBalance.vue";

@Component({
  components: {
    SelectCurrency,
    SelectAccountParent,
    SelectAccountType,
    SelectNormalBalance,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.accountingAccountStore as State,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "accountingAccountStore/setForm",
    }),
    ...mapActions({
      resetStore: "accountingAccountStore/resetStore",
    }),
  },
})
export default class FormModals extends Mixins(MNotificationVue) {
  store!: State;
  setForm!: (payload: Partial<FormValue>) => void;
  resetStore!: () => void;

  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: Boolean, required: true, default: false })
  visible!: boolean;

  @Prop({ type: String, required: false, default: "" })
  id!: string;

  loading = false;

  formRules = {
    code: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    description: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    accountTypeName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    normalBalance: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    currencyName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    parentName: [
      {
        required: false,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  @Watch("id")
  onChangeId(newValue: string): void {
    if (!newValue) return;
    this.getDetail(newValue);
  }

  get title(): string {
    const txtEdit = this.$t("lbl_edit_x", {
      x: this.$t("lbl_chart_of_account"),
    }).toString();
    const txtCreate = this.$t("lbl_create_x", {
      x: this.$t("lbl_chart_of_account"),
    }).toString();

    return this.id ? txtEdit : txtCreate;
  }

  getDetail(id: string): void {
    const { findById, mapDetailToForm } = useCoa();
    findById(id).then(res => {
      const form: FormValue = mapDetailToForm(res);
      this.setForm(form);
    });
  }

  handleCancel(): void {
    this.formModel.clearValidate([]);
    this.$emit("close");
  }

  onChangeIsParent(): void {
    this.setForm({
      parentName: "",
      parentId: "",
    });
  }

  onChangeAccountType(value?: Option<AccountingAccountTypeResponseDto>): void {
    this.setForm({
      accountTypeId: value?.meta?.id || "",
      accountTypeName: value?.label || "",
    });
  }

  onChangeCurrency(value?: Option<AccountingCurrencyResponseDTO>): void {
    this.setForm({
      currencyId: value?.meta?.id || "",
      currencyName: value?.label || "",
    });
  }

  onChangeParentAccount(value?: Option<AccountingAccountResponseDto>): void {
    this.setForm({
      parentName: value?.label || "",
      parentId: value?.meta?.id || "",
    });
  }

  validateForm(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (this.id) {
        // update
        this.handleUpdate(this.id, this.store.form);
      } else {
        // create
        this.handleCreate(this.store.form);
      }
    });
  }

  handleCreate(form: FormValue): void {
    const { create, mapFormToCreateDto } = useCoa();
    const req: AccountingAccountCreateRequestDto = mapFormToCreateDto(form);
    this.loading = true;
    create(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: req.code,
        });
        this.resetStore();
        this.emitSuccess(req.code);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleUpdate(id: string, form: FormValue): void {
    const { update, mapFormToUpdateDto } = useCoa();
    const req: AccountingAccountUpdateRequestDto = mapFormToUpdateDto(form);
    this.loading = true;
    update(id, req)
      .then(() => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: req.code,
        });
        this.resetStore();
        this.emitSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  emitSuccess(code?: string): void {
    this.$emit("success", { code });
  }
}
