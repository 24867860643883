

























































































































































































































































































































































































































































































































































































import { ModalStateBuilder, SearchBuilder } from "@/builder";
import { ModalAdditionalCost } from "@/components/InvoiceAp";
import ListProductBrand from "@/components/ProductBrand/ListProductBrand.vue";
import currencyFilter from "@/filters/currency.filter";
import { debounce } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import { useTaxRate } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { INVOICE_AP_SOURCE } from "@/models/enums/invoice-ap.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ProductResponseDto } from "@/models/interface/master-product";
import { AccountingTaxResponseDto } from "@/models/interface/master-tax";
import { CreateMerkDTO } from "@/models/interface/product.interface";
import { accountingAccountService } from "@/services/AccountingAccount.service";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import { settingsServices } from "@/services/settings.service";
import {
  ApInvoiceFormAdditionalCostState,
  ApInvoiceFormProductState,
  ApInvoiceFormState,
} from "@/store/account-payable/ap-invoice/types";
import { LabelInValue } from "@/types";
import { StringUtils } from "@/utils/StringUtils";
import {
  formatterNumber,
  formatterPercent,
  reverseFormatNumber,
  reverseFormatPercent,
} from "@/validator/globalvalidator";
import { Column } from "ant-design-vue/types/table/column";
import { Decimal } from "decimal.js-light";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

type DetailFormState = {
  partNumber: string;
  partName: string;
  partMerk: string;
  qty: number;
  uom: string;
  price: number;
  discountValue: number;
  discountPercent: number;
  incomeTax: string;
  incomeTaxRateValue: number;
  incomeTaxPercent: number;
  baseAmountDpp: number;
};

function buildDetailFormState(): DetailFormState {
  return {
    partNumber: "",
    partName: "",
    partMerk: "",
    qty: 0,
    uom: "",
    price: 0,
    discountValue: 0,
    discountPercent: 0,
    incomeTax: "",
    incomeTaxRateValue: 0,
    incomeTaxPercent: 0,
    baseAmountDpp: 0,
  };
}

@Component({
  mixins: [MNotificationVue],
  components: {
    ListProductBrand,
    ModalAdditionalCost,
  },
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
      storeAPForm: (st: any) => st.invoiceApStore.formState,
    }),
    ...mapGetters({
      allowEdit: "invoiceApStore/GET_ALLOW_TO_EDIT",
    }),
  },
  methods: {
    ...mapMutations({
      setAPForm: "invoiceApStore/setFormState",
    }),
    ...mapActions({
      addRowInvoiceAPLines: "invoiceApStore/ADD_ROW",
      calcAdditionalDiscount: "invoiceApStore/CALC_ADDITIONAL_DISCOUNT",
      recalculatePricing: "invoiceApStore/RECALCULATE_PRICING",
      updateDocReference: "invoiceApStore/updateDocReference",
      updateNumbering: "invoiceApStore/updateNumbering",
    }),
  },
})
export default class TabDetailsInvoiceAp extends Vue {
  storeAPForm!: ApInvoiceFormState;
  updateDocReference!: () => void;
  recalculatePricing!: () => void;
  updateNumbering!: () => void;
  setAPForm!: (payload: Partial<ApInvoiceFormState>) => void;

  formatterNumber = formatterNumber;
  formatterPercent = formatterPercent;
  reverseFormatNumber = reverseFormatNumber;
  reverseFormatPercent = reverseFormatPercent;
  currencyFilter = currencyFilter;

  productServiceCodeOptions: Option<ProductResponseDto>[] = [];
  productServiceNameOptions: Option<ProductResponseDto>[] = [];
  brandOptions: Option[] = [];
  expenseAccountOptions: Option[] = [];
  incomeTaxOptions: Option<AccountingTaxResponseDto>[] = [];
  taxCodeOptions: Option<AccountingTaxResponseDto>[] = [];
  iconBtnBrand: "plus" | "loading" = "plus";
  inputBrand: string | undefined;

  detailFormState: DetailFormState = buildDetailFormState();

  additionalCostModal = ModalStateBuilder.build<{
    record: ApInvoiceFormAdditionalCostState[];
    key: string;
  }>({
    record: [],
    key: "",
  });

  selectedRowKeys: string[] = [];
  get columnsTable(): Column[] {
    return [
      {
        title: this.$t("lbl_no"),
        dataIndex: "no",
        key: "no",
        width: 70,
        scopedSlots: { customRender: "no" },
      },
      {
        title: this.$t("lbl_document_reference"),
        dataIndex: "documentReference",
        key: "documentReference",
        width: 250,
        customRender: text => text || "-",
      },
      {
        title: this.$t("lbl_part_number"),
        key: "partNumber",
        width: 250,
        scopedSlots: { customRender: "partNumber" },
      },
      {
        title: this.$t("lbl_part_name"),
        key: "partName",
        width: 250,
        scopedSlots: { customRender: "partName" },
      },
      {
        title: this.$t("lbl_unit_code"),
        dataIndex: "unitCode",
        width: 200,
        customRender: text => text || "-",
      },
      {
        title: this.$t("lbl_brand"),
        key: "brand",
        width: 170,
        scopedSlots: { customRender: "brand" },
      },
      {
        title: this.$t("lbl_qty"),
        dataIndex: "qty",
        key: "qty",
        width: 150,
        scopedSlots: { customRender: "qty" },
      },
      {
        title: this.$t("lbl_uom"),
        dataIndex: "uom",
        key: "uom",
        width: 150,
        customRender: (_, record: ApInvoiceFormProductState) =>
          record.uom ? record.uom.label : "-",
      },
      {
        title: this.$t("lbl_price"),
        dataIndex: "price",
        key: "price",
        width: 200,
        scopedSlots: { customRender: "price" },
      },
      {
        title: this.$t("lbl_gross_value"),
        dataIndex: "gross",
        key: "gross",
        width: 200,
        customRender: text => currencyFilter(text),
      },
      {
        title: this.$t("lbl_expense_account"),
        dataIndex: "expenseAccount",
        key: "expenseAccount",
        width: 200,
        scopedSlots: { customRender: "expenseAccount" },
      },
      {
        title: this.$t("lbl_base_amount"),
        dataIndex: "baseAmount",
        key: "baseAmount",
        width: 200,
        customRender: (_, record: ApInvoiceFormProductState) =>
          currencyFilter(record.baseAmount.toNumber()),
      },
      ...(this.storeAPForm.source &&
      StringUtils.compare(INVOICE_AP_SOURCE.CAR, this.storeAPForm.source)
        ? [
            {
              title: this.$t("lbl_additional_cost"),
              key: "additionalCost",
              scopedSlots: { customRender: "additionalCost" },
              width: 150,
            },
          ]
        : []),
      {
        title: this.$t("lbl_income_tax"),
        dataIndex: "incomeTax",
        key: "incomeTax",
        width: 200,
        scopedSlots: { customRender: "incomeTax" },
      },
      {
        title: this.$t("lbl_tax_code"),
        dataIndex: "taxCode",
        key: "taxCode",
        width: 200,
        scopedSlots: { customRender: "taxCode" },
      },
      ...(this.storeAPForm.taxType === TAX_CALCULATION.INCLUSIVE &&
      !this.storeAPForm.isLuxuryGoods
        ? [
            {
              title: this.$t("lbl_inclusive_tax_code"),
              key: "inclusiveTax",
              width: 200,
              customRender: (_, record: ApInvoiceFormProductState) =>
                record.inclusiveTax?.label || "-",
            },
          ]
        : []),
      {
        title: this.$t("lbl_tax_amount"),
        dataIndex: "taxValue",
        key: "taxValue",
        width: 200,
        customRender: (_, record: ApInvoiceFormProductState) =>
          currencyFilter(record.taxValue.toNumber()),
      },
      {
        title: this.$t("lbl_sub_total"),
        dataIndex: "subTotal",
        key: "subTotal",
        width: 200,
        customRender: (_, record: ApInvoiceFormProductState) =>
          currencyFilter(record.subTotal.toNumber()),
      },
      {
        title: this.$t("lbl_description"),
        dataIndex: "description",
        key: "description",
        width: 200,
        scopedSlots: { customRender: "description" },
      },
      {
        title: this.$t("lbl_action"),
        key: "operation",
        width: 200,
        scopedSlots: { customRender: "operation" },
        align: "center",
      },
    ] as Column[];
  }
  formRules = {
    partNumber: {
      label: "lbl_part_number",
      name: "partNumber",
      placeholder: "lbl_part_number_placeholder",
    },
    partName: {
      label: "lbl_part_name",
      name: "partName",
      placeholder: "lbl_part_name_placeholder",
    },
    partMerk: {
      label: "lbl_brand",
      name: "partMerk",
      placeholder: "lbl_part_merk_placeholder",
    },
    qty: {
      label: "lbl_qty",
      name: "qty",
      placeholder: "lbl_qty_placeholder",
    },
    uom: {
      label: "lbl_uom",
      name: "uom",
      placeholder: "lbl_uom_placeholder",
    },
    price: {
      label: "lbl_price",
      name: "price",
      placeholder: "lbl_price_placeholder",
    },
    discountValue: {
      label: "lbl_discount",
      name: "discountValue",
      placeholder: "lbl_discount_placeholder",
    },
    discountPercent: {
      label: "lbl_discount",
      name: "discountPercent",
      placeholder: "lbl_discount_placeholder",
    },
    incomeTaxRateValue: {
      label: "lbl_income_tax_rate",
      name: "incomeTaxRateValue",
      placeholder: "lbl_income_tax_rate_placeholder",
    },
    incomeTaxPercent: {
      label: "lbl_income_tax_rate",
      name: "incomeTaxPercent",
      placeholder: "lbl_income_tax_rate_placeholder",
    },
    baseAmountDpp: {
      label: "lbl_base_amount_dpp",
      name: "baseAmountDpp",
      placeholder: "lbl_base_amount_dpp_placeholder",
    },
  };
  modal = {
    show: false,
    index: -1,
  };

  created(): void {
    this.getProductServiceOptions();
    this.getBrandOptions();
    this.getExpenseAccountOptions();
    this.getIncomeTaxOptions();
    this.getVatInTaxOptions();
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  setBasedOnDetailProduct(id: string, idxRow: number): void {
    productService
      .getDetailProduct(id)
      .then(
        async ({ baseUnit, baseUnitId, purchaseTaxName, purchaseTaxId }) => {
          const taxRate = await useTaxRate(purchaseTaxId);
          this.storeAPForm.products[idxRow].taxRate = taxRate;
          this.storeAPForm.products[idxRow].uom = {
            label: baseUnit,
            key: baseUnitId,
          };
          this.storeAPForm.products[idxRow].taxCode = {
            label: purchaseTaxName,
            key: purchaseTaxId,
          };
        }
      );
  }

  onChangeQty(e: number, record: ApInvoiceFormProductState): void {
    record.qty = e;
    this.recalculatePricing();
  }

  onChangePrice(e: number, index: number, source: "modal" | "table"): void {
    if (source === "modal") {
      this.detailFormState.price = e;
    } else {
      this.storeAPForm.products[index].price = e;
    }

    this.recalculatePricing();
  }

  onChangeExpenseAccount(
    record: ApInvoiceFormProductState,
    val?: LabelInValue
  ) {
    record.expenseAccount = val;
  }

  onChangeTaxCode(record: ApInvoiceFormProductState, val?: LabelInValue): void {
    const options = record.isSearchTaxCode
      ? record.taxCodeOptions
      : this.taxCodeOptions;
    const option = options.find(item => item.key === val?.key);
    record.taxCode = val;
    record.taxRate = option?.meta?.rate ?? 0;
    this.recalculatePricing();
  }

  onChangeDescription(record: ApInvoiceFormProductState, val?: string): void {
    record.description = val ?? null;
  }

  onChangeIncomeTax(
    record: ApInvoiceFormProductState,
    val?: LabelInValue
  ): void {
    const options = record.isSearchIncomeTax
      ? record.incomeTaxOptions
      : this.incomeTaxOptions;
    const option = options.find(item => item.key === val?.key);
    record.incomeTax = val;
    record.incomeTaxRate = option?.meta?.rate ?? 0;
    this.recalculatePricing();
  }

  onChangeDiscount(value: number, column: "percent" | "amount"): void {
    const fields = {
      discountValue: 0,
      percentDiscount: 0,
    };
    const gross = new Decimal(this.detailFormState.price || 0).times(
      this.detailFormState.qty
    );

    if (column === "percent") {
      const percentage = new Decimal(value || 0).dividedBy(100);
      const final = gross.times(percentage).toNumber();
      fields.percentDiscount = value || 0;
      fields.discountValue = final;
    } else {
      const amount = new Decimal(value || 0);
      const final = amount
        .dividedBy(gross || 1)
        .times(100)
        .toNumber();
      fields.percentDiscount = final;
      fields.discountValue = value || 0;
    }

    this.detailFormState.discountPercent = fields.percentDiscount;
    this.detailFormState.discountValue = fields.discountValue;
  }

  showMore(record: ApInvoiceFormProductState): void {
    const index = this.storeAPForm.products.findIndex(
      item => item.key === record.key
    );
    this.modal.show = true;
    this.modal.index = index;
    this.setDetailForm(record);
  }

  setDetailForm(record: ApInvoiceFormProductState) {
    const state = buildDetailFormState();

    state.partNumber = record.partNumber?.label ?? "";
    state.partName = record.partName?.label ?? "";
    state.partMerk = record.brand ?? "";
    state.qty = record.qty;
    state.uom = record.uom?.label ?? "";
    state.price = record.price;
    state.discountValue = record.discountValue;
    state.discountPercent = record.percentDiscount;
    state.incomeTax = record.incomeTax?.label ?? "";
    state.incomeTaxRateValue = Number(record.incomeTaxValue);
    state.incomeTaxPercent = record.incomeTaxRate;
    state.baseAmountDpp = Number(record.baseAmount);

    this.detailFormState = state;
  }

  handleSaveModal() {
    this.storeAPForm.products[this.modal.index].qty = this.detailFormState.qty;
    this.storeAPForm.products[this.modal.index].price =
      this.detailFormState.price;
    this.storeAPForm.products[this.modal.index].discountValue =
      this.detailFormState.discountValue;
    this.storeAPForm.products[this.modal.index].percentDiscount =
      this.detailFormState.discountPercent;

    this.modal.show = false;
    this.recalculatePricing();
  }

  onSelectRow(value: string[]): void {
    this.selectedRowKeys = value;
  }

  handleAddRow(): void {
    this.addRowInvoiceAPLines();
  }

  handleDeleteRow(): void {
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm"),
      content: this.$t("lbl_modal_delete_info", {
        count: this.selectedRowKeys.length,
      }),
      onOk: this.deleteInvoiceAPLine,
    });
  }

  deleteInvoiceAPLine(): void {
    const newSource = this.storeAPForm.products.filter(data => {
      if (this.selectedRowKeys.includes(data.key) && data.id) {
        this.storeAPForm.deletedLineIds.push(data.id);
      }
      return !this.selectedRowKeys.includes(data.key);
    });

    this.setAPForm({
      products: newSource,
    });
    this.updateDocReference();
    this.updateNumbering();
    this.selectedRowKeys = [];
    this.recalculatePricing();
  }

  onChangeAdditionalDiscount(value: number, field: "amount" | "percent"): void {
    this.calcAdditionalDiscount({ value, field });
    this.recalculatePricing();
  }

  getProductServiceOptions(): void {
    const params = new RequestQueryParams();
    params.search = new SearchBuilder().push(["type", "SERVICE"]).build();
    this.getProductList(params).then(([nameOptions, codeOptions]) => {
      this.productServiceCodeOptions = codeOptions;
      this.productServiceNameOptions = nameOptions;
    });
  }

  async getProductList(
    params?: RequestQueryParamsModel
  ): Promise<Option<ProductResponseDto>[][]> {
    const response = await logisticServices.getListProduct(params);
    const nameOptions: Option<ProductResponseDto>[] = [];
    const codeOptions: Option<ProductResponseDto>[] = [];

    response.data.forEach(item => {
      nameOptions.push({
        label: item.name,
        key: item.id,
        value: item.id,
        meta: item,
      });
      codeOptions.push({
        label: item.code,
        key: item.id,
        value: item.id,
        meta: item,
      });
    });
    return [nameOptions, codeOptions];
  }

  onChangePartService(
    record: ApInvoiceFormProductState,
    column: "code" | "name",
    val?: LabelInValue
  ): void {
    const index = record.no - 1;

    record.partNumber = undefined;
    record.partName = undefined;
    record.uom = undefined;
    record.taxCode = undefined;
    record.taxRate = 0;

    if (!val) return;

    let options: Option<ProductResponseDto>[] = [];
    if (column === "code") {
      options = record.isSearchProductServiceCode
        ? record.productServiceCodeOptions
        : this.productServiceCodeOptions;
    } else {
      options = record.isSearchProductServiceName
        ? record.productServiceNameOptions
        : this.productServiceNameOptions;
    }
    const option = options.find(item => item.key === val.key);
    if (!option) return;

    record.partNumber = {
      key: val.key,
      label: option.meta?.code ?? "",
    };
    record.partName = {
      key: val.key,
      label: option.meta?.name ?? "",
    };
    this.setBasedOnDetailProduct(val.key, index);
  }

  onSearchPartService(
    record: ApInvoiceFormProductState,
    column: "code" | "name",
    val?: string
  ): void {
    debounce(() => {
      if (column === "code") {
        record.isSearchProductServiceCode = true;
      } else {
        record.isSearchProductServiceName = true;
      }
      const index = this.storeAPForm.products.findIndex(
        item => item.key === record.key
      );
      if (index === -1) return;

      const queries: string[] = [
        new SearchBuilder().push(["type", "SERVICE"]).build(),
      ];
      const params = new RequestQueryParams();
      if (val) {
        const searchKey = column === "code" ? "code" : "name";
        queries.unshift(
          new SearchBuilder().push([searchKey, val], { like: "both" }).build()
        );
      }
      params.search = queries.join(SearchBuilder.AND);

      record.loadingProductServiceCode = column === "code";
      record.loadingProductServiceName = column === "name";
      this.getProductList(params)
        .then(([nameOptions, codeOptions]) => {
          if (column === "code") record.productServiceCodeOptions = codeOptions;
          else record.productServiceNameOptions = nameOptions;
        })
        .finally(() => {
          record.loadingProductServiceCode = false;
          record.loadingProductServiceName = false;
        });
    });
  }

  onSearchBrand(record: ApInvoiceFormProductState, val?: string): void {
    this.inputBrand = val;
    debounce(() => {
      record.isSearchBrand = true;
      const params = new RequestQueryParams();
      if (val) {
        params.search = new SearchBuilder()
          .push(["name", val], { like: "end" })
          .build();
      }
      record.loadingBrand = true;
      this.getBrandList(params)
        .then(response => {
          record.brandOptions = response;
        })
        .finally(() => {
          record.loadingBrand = false;
        });
    });
  }

  getBrandOptions() {
    this.getBrandList().then(response => {
      this.brandOptions = response;
    });
  }

  async getBrandList(params?: RequestQueryParamsModel) {
    const response = await productService.listMerk(params);
    return response.data.map(item => ({
      label: item,
      value: item,
      key: generateUUID(),
    }));
  }

  addNewBrand(record: ApInvoiceFormProductState): void {
    if (this.iconBtnBrand === "loading" || !this.inputBrand) return;
    const payload: CreateMerkDTO = {
      name: this.inputBrand,
    };
    this.iconBtnBrand = "loading";
    productService
      .addMerk(payload)
      .then(response => {
        record.brandOptions.push({
          label: response[0],
          key: generateUUID(),
          value: response[0],
        });
      })
      .finally(() => (this.iconBtnBrand = "plus"));
  }

  onChangeBrand(record: ApInvoiceFormProductState, val?: string) {
    record.brand = val;
  }

  getExpenseAccountOptions() {
    this.getExpenseAccountList().then(response => {
      this.expenseAccountOptions = response;
    });
  }

  onSearchExpenseAccount(record: ApInvoiceFormProductState, val?: string) {
    record.isSearchExpenseAccount = true;
    debounce(() => {
      record.loadingExpenseAccount = true;
      this.getExpenseAccountList(val)
        .then(response => {
          record.expenseAccountOptions = response;
        })
        .finally(() => {
          record.loadingExpenseAccount = false;
        });
    });
  }

  async getExpenseAccountList(search?: string): Promise<Option[]> {
    const params = new RequestQueryParams();
    const EXPENSE_ACCOUNT_PREFIX = "6";
    const criteria = new SearchBuilder()
      .push(["active", "true"])
      .and()
      .push(["isParent", "false"])
      .build();

    const queries: string[] = [criteria];
    if (search) {
      const searchByCode = new SearchBuilder()
        .push(["code", `${EXPENSE_ACCOUNT_PREFIX}${search}`], { like: "end" })
        .or()
        .push(["description", search], { like: "both" })
        .build();
      queries.unshift(searchByCode);
    } else {
      const searchByCode = new SearchBuilder()
        .push(["code", EXPENSE_ACCOUNT_PREFIX], { like: "end" })
        .build();
      queries.unshift(searchByCode);
    }

    params.search = queries.join(SearchBuilder.AND);

    const response = await accountingAccountService.getList(params);
    return response.data.map(item => ({
      label: `${item.code} ${item.description}`,
      value: item.id,
      key: item.id,
    }));
  }

  getIncomeTaxList(search?: string) {
    return this.getTaxList("INCOME_TAX_PAYABLES", search);
  }

  async getTaxList(
    taxType: "INCOME_TAX_PAYABLES" | "VAT_IN",
    search?: string
  ): Promise<Option<AccountingTaxResponseDto>[]> {
    const params = new RequestQueryParams();
    const criteria = new SearchBuilder().push(["taxType", taxType]).build();
    const queries = [criteria];

    if (search) {
      const searchCriteria = new SearchBuilder()
        .push(["code", search], { like: "both" })
        .or()
        .push(["description", search], { like: "both" })
        .or()
        .push(["taxAccount.description", search], { like: "both" })
        .or()
        .push(["taxAccount.code", search], { like: "both" })
        .build();
      queries.unshift(searchCriteria);
    }

    params.search = queries.join(SearchBuilder.AND);

    const response = await settingsServices.getListTax(params);
    return response.data.map(item => ({
      label: item.code,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  }

  getIncomeTaxOptions() {
    this.getIncomeTaxList().then(response => {
      this.incomeTaxOptions = response;
    });
  }

  onSearchIncomeTax(record: ApInvoiceFormProductState, val?: string) {
    record.isSearchIncomeTax = true;
    debounce(() => {
      record.loadingIncomeTax = true;
      this.getIncomeTaxList(val)
        .then(response => {
          record.incomeTaxOptions = response;
        })
        .finally(() => {
          record.loadingIncomeTax = false;
        });
    });
  }

  onSearchTaxCode(record: ApInvoiceFormProductState, val?: string) {
    record.isSearchTaxCode = true;
    debounce(() => {
      record.loadingTaxCode = true;
      this.getVatInTaxList(val)
        .then(response => {
          record.taxCodeOptions = response;
        })
        .finally(() => {
          record.loadingTaxCode = false;
        });
    });
  }

  getVatInTaxList(search?: string) {
    return this.getTaxList("VAT_IN", search);
  }

  getVatInTaxOptions() {
    this.getVatInTaxList().then(response => {
      this.taxCodeOptions = response;
    });
  }

  showAdditionalCostModal(record: ApInvoiceFormProductState): void {
    this.additionalCostModal.props.record = [...record.additionalCosts];
    this.additionalCostModal.props.key = record.key;
    this.additionalCostModal.open();
  }

  onSaveAdditionalCost({
    payload,
  }: {
    payload: ApInvoiceFormAdditionalCostState[];
  }): void {
    const index = this.storeAPForm.products.findIndex(
      product => product.key === this.additionalCostModal.props.key
    );
    this.storeAPForm.products[index].additionalCosts = [...payload];
    const total = payload.reduce(
      (left, right) => new Decimal(left).plus(right.amount).toNumber(),
      0
    );
    this.storeAPForm.products[index].totalAdditionalCost = total;
    this.recalculatePricing();
  }
}
