



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingAccountTypeResponseDto } from "@/models/interface/accounting-account";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectAccountType extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingAccountTypeResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAllAccountType, toAccountTypeOptions } = useCoa();
    this.loading = true;
    findAllAccountType(params)
      .then(({ data }) => {
        this.options = toAccountTypeOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();

    if (val) {
      params.search = builder.push(["name", val], { like: "both" }).build();
    }

    this.fetchOptions(params);
  }

  findOption(
    value: string
  ): Option<AccountingAccountTypeResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
